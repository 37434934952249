import { makeAutoObservable } from 'mobx';
import { Community as CommunityService } from 'services/Community';
import { CommunityBoardDto, CommunityDto, PostDto } from 'services/data-contracts';
// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';

class CommunityStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: CommunityService<unknown> = new CommunityService({
    baseURL: REST_API_URL,
  });

  communities: Array<CommunityDto>;

  posts: Array<PostDto>;

  boards: Array<CommunityBoardDto>;

  *getCommunities() {
    const { data } = yield this.api.getCommunities();
    this.communities = data;
  }

  *getCommunityBoards(communityId: number) {
    const { data } = yield this.api.getCommunityBoards(communityId);
    this.boards = data;
  }

  *getPosts(communityId: number, boardId?: number, size?: number) {
    const { data } = yield this.api.getCommunityPosts(communityId, {
      page: 0,
      size: typeof size === 'undefined' ? 5 : size,
      'board.id': boardId,
    });
    this.posts = data;
    return data;
  }
}

export default CommunityStore;
