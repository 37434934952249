/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import { refreshErrorHandle, RefreshToken } from 'libs/commons/RefreshToken';
import _ from 'lodash';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;

  noToast?: boolean;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });

    this.instance.interceptors.response.use(
      function (response) {
        return Promise.resolve({
          data: response.data,
          response: response,
          error: { hasError: false, data: response },
          headers: response.headers,
        });
      },
      async function (err) {
        // alert(err.response?.data?.errorMessage);
        const accessToken = await localStorage.getItem('accessToken');
        const refreshToken = await localStorage.getItem('refreshToken');
        const deviceInfo = await localStorage.getItem('deviceInfo');
        const errors = {
          request: err.request,
          response: err.response,
          // accessToken,
          // deviceInfo,
          // refreshToken,
        };
        await localStorage.setItem('httpError', JSON.stringify(errors));
        // 미인증 401을 처리하되, 잘못된 로그인에서 받은 401은 제외함
        if (err.response?.status === 401) {
          window.location.href = 'error';

          // localStorage.removeItem('accessToken');
          // localStorage.removeItem('me');
          // localStorage.removeItem('refreshToken');
        } else {
          return Promise.resolve({
            data: null,
            error: err.response,
            response: err.response,
          });
        }
      },
    );

    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      // @ts-ignore
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
  // @ts-ignore
  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        // @ts-ignore
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
      // @ts-ignore
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    // let token: string | null;

    // if (token === null) {
    //   await localStorage.removeItem('accessToken');
    //   await localStorage.removeItem('refreshToken');
    // } else {
    /** 리프레시 토큰 */
    this.instance.interceptors.request.use(await RefreshToken, refreshErrorHandle);
    const token = await localStorage.getItem('accessToken');
    // }

    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (!_.isEmpty(token) && !_.isUndefined(requestParams.headers)) {
      requestParams.headers['Authorization'] = `Bearer ${token}`;
    }

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      // @ts-ignore
      requestParams.headers.common = { Accept: '*/*' };
      // @ts-ignore
      requestParams.headers.post = {};
      // @ts-ignore
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        // ...(!_.isEmpty(token) ? { Authorization: "type" } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}
