import axios, { AxiosRequestConfig } from 'axios';
import { REST_API_URL } from 'stores/AppStore';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment';

interface TokenDto {
  email: string;
  exp: number;
  iat: number;
  isActive: number;
  isAdmin: number;
  isVerify: number;
  userId: number;
  userName: number;
}

const RefreshToken = async (
  config?: AxiosRequestConfig,
  force?: boolean,
): Promise<AxiosRequestConfig | undefined> => {
  // const { authStore } = useStore();
  const accessToken = await localStorage.getItem('accessToken');
  const refreshToken = await localStorage.getItem('refreshToken');
  const d = new Date();
  const decoded = jwtDecode(String(accessToken)) as TokenDto;

  // /** 남은 엑세스 토큰 만료 시간 */

  if (decoded.exp < d.getTime() / 1000 || typeof config === 'undefined') {
    const deviceInfo = await localStorage.getItem('deviceInfo');
    if (deviceInfo !== null) {
      const deviceInfo2 = JSON.parse(deviceInfo);
      await axios({
        method: 'post',
        url: `${REST_API_URL}v1/refresh-token`,
        data: {
          accessToken: String(accessToken),
          refreshToken: String(refreshToken),
          // },
        },
        headers: {
          'Content-Type': 'application/json',
          'device-model': deviceInfo2.deviceModel, // 'Apple iPhone',
          'device-os': deviceInfo2.deviceOs, // 'ios 15.2',
          'app-version': deviceInfo2.appVersion, // '1.1.9 (debug)',
          'device-token': deviceInfo2.deviceToken, // '47416625-0604-4F90-A94E-DD5B7628A06C',
          'push-token': deviceInfo2.pushToken,
          accept: 'application/json',
          'refresh-token': String(refreshToken),
        },
      })
        .then(async (response) => {
          await localStorage.setItem('accessToken', response.data.accessToken);
          await localStorage.setItem('refreshToken', response.data.refreshToken);
        })
        .catch((response) => {});
    }
  }

  if (typeof config !== 'undefined') {
    return config;
  }
};

const RefreshTokenForce = async () => {
  const accessToken = await localStorage.getItem('accessToken');
  const refreshToken = await localStorage.getItem('refreshToken');
  const deviceInfo = await localStorage.getItem('deviceInfo');

  if (deviceInfo !== null) {
    const deviceInfo2 = JSON.parse(deviceInfo);

    await axios({
      method: 'post',
      url: `${REST_API_URL}v1/refresh-token`,
      data: {
        accessToken: String(accessToken),
        refreshToken: String(refreshToken),
        // },
      },
      headers: {
        'Content-Type': 'application/json',
        'device-model': deviceInfo2.deviceModel, // 'Apple iPhone',
        'device-os': deviceInfo2.deviceOs, // 'ios 15.2',
        'app-version': deviceInfo2.appVersion, // '1.1.9 (debug)',
        'device-token': deviceInfo2.deviceToken, // '47416625-0604-4F90-A94E-DD5B7628A06C',
        'push-token': deviceInfo2.pushToken,
        accept: 'application/json',
        'refresh-token': String(refreshToken),
      },
    })
      .then(async (response) => {
        await localStorage.setItem('accessToken', response.data.accessToken);
        await localStorage.setItem('refreshToken', response.data.refreshToken);
      })
      .catch((response) => {
        alert(response);
      });
  } else {
    alert('디바이스정보가 존재하지 않습니다. 곧 조치하도록 하겠습니다!');
  }
};

const refreshErrorHandle = (err: any) => {
  localStorage.removeItem('refreshToken');
};

export { RefreshToken, RefreshTokenForce, refreshErrorHandle };
