/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FileDto, StreamingResponseBody } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class File<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 파일 다운로드
   *
   * @tags file
   * @name DownloadFile
   * @summary 파일 다운로드
   * @request GET:/v1/files/{fileId}
   * @secure
   */
  downloadFile = (fileId: number, params: RequestParams = {}) =>
    this.request<StreamingResponseBody, any>({
      path: `/v1/files/${fileId}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 파일 변경
   *
   * @tags file
   * @name UpdateFile
   * @summary 파일 변경
   * @request PUT:/v1/files/{fileId}
   * @secure
   */
  updateFile = (
    fileId: number,
    data: { file?: File; thumbnail?: File },
    params: RequestParams = {},
  ) =>
    this.request<FileDto, any>({
      path: `/v1/files/${fileId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 파일 삭제
   *
   * @tags file
   * @name DeleteFile
   * @summary 파일 삭제
   * @request DELETE:/v1/files/{fileId}
   * @secure
   */
  deleteFile = (fileId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/files/${fileId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 파일 업로드
   *
   * @tags file
   * @name UploadFile
   * @summary 새 파일 업로드
   * @request POST:/v1/files
   * @secure
   */
  uploadFile = (
    query: {
      fileType:
        | 'icon'
        | 'profile'
        | 'logo'
        | 'cover'
        | 'business'
        | 'post'
        | 'chat'
        | 'report'
        | 'item'
        | 'property';
    },
    data: { file?: File[]; thumbnail?: File[] },
    params: RequestParams = {},
  ) =>
    this.request<FileDto[], any>({
      path: `/v1/files`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
}
