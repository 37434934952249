import { makeObservable, observable, flow } from 'mobx';
import { ItemDto } from 'services/data-contracts';
import { Item as MarketService } from 'services/Item';
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';

class MarketStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      items: observable,
      getItems: flow,
      getSuggestedItems: flow,
    });
  }

  api: MarketService<unknown> = new MarketService({
    baseURL: REST_API_URL,
  });

  items: Array<ItemDto>;

  *getItems() {
    const { response } = yield this.api.getItems({ status: 'ON_SALE', page: 0, size: 10 });
    this.items = response.data;
  }

  *getSuggestedItems() {
    const { response } = yield this.api.getSuggestedItems({ page: 0, size: 10 });
    console.log(response);

    this.items = response.data;
  }
}

export default MarketStore;
