import { makeAutoObservable } from 'mobx';
import { User as UserService } from 'services/User';
import { REST_API_URL } from 'stores/AppStore';
import { isValid } from 'libs/helper/utils';

import _ from 'lodash';
import { UserDto } from 'services/data-contracts';

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: UserService<unknown> = new UserService({
    baseURL: REST_API_URL,
  });

  userInfo: UserDto;

  logoutStatus: any;

  *getMyInfo() {
    const { data } = yield this.api.getMyInfo();
    this.userInfo = data;
  }

  // *logout() {
  //   const { status } = yield this.api.logout();
  //   this.logoutStatus = status;
  // }
}

export default UserStore;
